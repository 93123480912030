import { defineComponent, computed } from '@vue/composition-api';
import { useSharePaymentLink } from '@/shared/composables/useSharePaymentLink';
import { BookingRoutes } from '@/Booking/booking.const';
export default defineComponent({
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props, context) {
        const { root } = context;
        const prefix = root.$route.params.locale && root.$route.params.locale !== 'nl'
            ? `${root.$route.params.locale}/`
            : '';
        const link = computed(() => `${window.location.origin}/${prefix}${BookingRoutes.BOOKING}${BookingRoutes.PAYMENT}/${props.booking.booking_hash}`);
        const { setLink } = useSharePaymentLink(props.booking, root, prefix);
        return {
            link,
            setLink
        };
    }
});
