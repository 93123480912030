import { BookingRoutes } from '@/Booking/booking.const';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
export const useSharePaymentLink = (booking, root, prefix) => {
    const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
    const setLink = async () => {
        if (process.browser) {
            const link = `${window.location.origin}/${prefix}${BookingRoutes.BOOKING}${BookingRoutes.PAYMENT}/${booking.booking_hash}`;
            try {
                root.$copyText(link);
                addToast({
                    text: root.$i18n.t('booking.details.pay.share_to_pay.success'),
                    type: 'success',
                    dismissAfter: 2000
                });
            }
            catch (e) {
                addToast({
                    text: root.$i18n.t('booking.details.pay.share_to_pay.error'),
                    type: 'danger',
                    dismissAfter: 2000
                });
            }
        }
    };
    return {
        setLink
    };
};
